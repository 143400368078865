<template>
  <div class="ships view">
    <app-header title="Ingame - Ships"/>
    <progress-linear v-if="loading" :indeterminate="true"/>
    <div class="control-bar" :class="{'control-bar--disabled': loading}">
      <div class="control-bar__info mr">
        <div class="control-bar__info-title">{{ __('common.total') }}:</div>
        <div class="control-bar__info-value">{{ pagination.total }}</div>
      </div>
      <div class="control-bar__search mr">
        <select-reference
            v-model="shipClassId"
            :endpoint="'/ingame/expo/shipclass'"
            :nothing-selected-message="__('filterByShipClass')"
            :hide-info="true"
            @input="refreshDelayed(100)"
        />
      </div>
      <div class="control-bar__search mr">
        <select-reference
            v-model="contributorId"
            :endpoint="'/ingame/civilization/player'"
            :field-icon="'avatar'"
            :nothing-selected-message="__('filterByContributor')"
            :hide-info="true"
            @input="refreshDelayed(100)"
        />
      </div>
      <div class="control-bar__search mr">
        <select-reference
            v-model="organizationId"
            :endpoint="'/ingame/civilization/organization'"
            :field-icon="'logo'"
            :nothing-selected-message="__('filterByOrganization')"
            :hide-info="true"
            @input="refreshDelayed(100)"
        />
      </div>
      <div class="control-bar__search mr">
        <select-reference
            v-model="userId"
            :endpoint="'/community/user'"
            :field-icon="'avatar'"
            :field-title="'userName'"
            :nothing-selected-message="__('filterByUser')"
            :hide-info="true"
            @input="refreshDelayed(100)"
        />
      </div>
      <div class="control-bar__search mr">
        <select-reference
            v-model="coreSize"
            :force-available-items="coreSizes"
            :field-value="'value'"
            :field-title="'title'"
            :nothing-selected-message="__('filterByCoreSize')"
            :hide-info="true"
            @input="refreshDelayed(100)"
        />
      </div>
      <text-field
          class="control-bar__search"
          v-model="searchTerm"
          :label="__('common.search_term')"
          max-length="50"
          :hide-details="true"
          :clearable="true"
          @keyup:enter="refreshDelayed(100)"
          @click:clear="refreshDelayed(100)"
      />
      <div class="control-bar__buttons">
        <div class="control-bar__button" :title="__('common.refresh')" @click="refresh" v-slashes>
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/refresh.svg"
               alt="refresh"/>
        </div>
        <div class="control-bar__button"
             :title="__('common.add')" @click="addShip()" v-slashes>
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/add.svg"
               alt="add"/>
        </div>
      </div>
    </div>
    <div class="main-content-padding">
      <pagination
          class="mt"
          :count-pages="pagination.pages"
          :total-visible="10"
          :page="pagination.page"
          :disabled="loading"
          @paginate="paginate"
      />
      <ship-tiles class="mt" :ships="ships"/>
      <pagination
          class="mt"
          :count-pages="pagination.pages"
          :total-visible="10"
          :page="pagination.page"
          :disabled="loading"
          @paginate="paginate"
      />
    </div>
    <edit-ship ref="editShip" @updated="refresh" @created="editShip"/>
  </div>
</template>

<script>
import apiSecured from '../../../api/secured';
import DataTable from '../../../components/DataTable';
import AppHeader from '../../../components/AppHeader';
import ShipTiles from '../../../components/Ingame/Expo/ShipTiles';
import { notificationsMixin } from '../../../mixins/notifications';
import { localizationMixin } from '../../../mixins/localization';
import { paginationMixin } from "../../../mixins/pagination";
import { pageTitleMixin } from "../../../mixins/pageTitle";
import EditShip from "../../../components/Ingame/Expo/EditShip";

export default {
  mixins: [notificationsMixin, localizationMixin, paginationMixin, pageTitleMixin],
  components: {
    AppHeader,
    DataTable,
    ShipTiles,
    EditShip
  },
  data: () => ({
    loading: false,
    ships: [],
    shipClassId: null,
    contributorId: null,
    organizationId: null,
    userId: null,
    coreSizes: [],
    coreSize: null,
  }),
  methods: {
    addShip() {
      this.$refs.editShip.showCreate({ inChargeId: this.$store.state.user.id });
    },
    async editShip(ship) {
      await this.refresh();
      this.$refs.editShip.showEdit(ship);
    },
    refreshDelayed(delay) {
      setTimeout(this.refresh, delay);
    },
    refresh() {
      this.loading = true;
      let params = this.getPaginationParams();
      params.userId = this.userId;
      params.shipClassId = this.shipClassId;
      params.contributorId = this.contributorId;
      params.organizationId = this.organizationId;
      params.coreSize = this.coreSize;
      this.$router.push({ name: this.$route.name, query: params }).catch(err => {
      });
      apiSecured.get('/ingame/expo/ship', { params }).then(async (res) => {
        this.ships = await this.enrichRecords(res.data);
        this.updatePagination(res);
        this.loading = false;
        this.showInfoNotification(this.__('common.ships_loaded'));
      }).catch((error) => {
        this.loading = false;
        this.showErrorNotifications(error)
      });
    },
    async enrichRecords(ships) {
      if(ships.length === 0) {
        return ships;
      }
      const shipClassIds = [];
      const shipIds = [];
      let shipClasses = [];
      let shipImages = [];

      ships.forEach(ship => {
        shipIds.push(ship.id);
        if (!shipClassIds.includes(ship.shipClassId)) {
          shipClassIds.push(ship.shipClassId);
        }
      });

      await apiSecured.get(`/ingame/expo/shipclass/(${shipClassIds.join(',')})`, {
        params: {
          fields: 'id,name,color'
        },
      }).then((res) => {
        shipClasses = res.data;
        this.showInfoNotification('Ship types have been reloaded');
      }).catch((error) => {
        this.showErrorNotifications(error)
      });

      const params = {
        orderBy: 'sorting asc'
      }
      await apiSecured.get(`/ingame/expo/shipimage/by-ships/(${shipIds.join(',')})`, { params }).then((res) => {
        shipImages = res.data
        this.showInfoNotification('Ship images have been reloaded');
      }).catch((error) => {
        this.showErrorNotifications(error)
      });

      const fileIds = shipImages.map(shipImage => shipImage.fileId);
      if(fileIds.length > 0) {
        await apiSecured.get(`/fal/file/(${fileIds.join(',')})`).then((res) => {
          shipImages = shipImages.map(shipImage => {
            shipImage.file = res.data.find(file => file.id === shipImage.fileId);
            return shipImage;
          });
          this.showInfoNotification('Files have been reloaded');
        }).catch((error) => {
          this.showErrorNotifications(error)
        });
      }

      ships = ships.map(ship => {
        ship.contributors = ship.contributors.sort((a,b) => {
          return new Date(a.createdAt) - new Date(b.createdAt)
        })
        ship.shipClass = shipClasses.find(shipClass => shipClass.id === ship.shipClassId);
        ship.shipImages = shipImages.filter(shipImage => shipImage.shipId === ship.id);
        return ship;
      });

      return ships;
    }
  },
  created() {
    this.setPageTitle('EXPO ' + this.__('ships'));
    this.coreSizes = [
      { value: 'xs', title: 'XS' },
      { value: 's', title: 'S' },
      { value: 'm', title: 'M' },
      { value: 'l', title: 'L' },
    ];
    this.refresh();
  }
}
</script>
